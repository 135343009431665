import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getCateringOrder, searchColleague, updateCateringOrderStatus, createUpdateOrder, getDepartment, getCateringProduct, getAdminLocation, getAllCompanyNameQuery, getCateringOrderStatus, } from "../../services/ApiServices"
import { changeAllCateringOrderStatusValue, changeAllDepartmentValue, changeAllLocationValue } from "../../store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { InputMask } from "@react-input/mask"
import { emailRegex } from "../../const"
import { Tooltip } from "react-tooltip"
import { toast } from "react-hot-toast"
import CreatableSelect from "react-select/creatable"
import Loader from "../Loader/Loader"
import moment from "moment/moment"
import Select from "react-select"
import "./OrderDetails.css"

// ==== Imported Image ====
import iconEdit from "../../assets/img/icon-edit.svg"
import iconClose from "../../assets/img/icon-close.svg"
import iconPin from "../../assets/img/icon-pin.svg"
import iconUsers from "../../assets/img/icon-users.svg"
import iconUser from "../../assets/img/icon-user.svg"
import iconClock from "../../assets/img/icon-clock.svg"
import iconAdd from "../../assets/img/icon-add.svg"
import iconTrash from "../../assets/img/icon-trash.svg"
import iconArrow from "../../assets/img/icon-arrow-down.svg"
import iconNotStarted from "../../assets/img/icon-not-started.svg"
import iconInProgress from "../../assets/img/icon-in-progress.svg"
import iconCompleted from "../../assets/img/icon-completed.svg"
import iconOrderNumber from "../../assets/img/icon-order-number.svg"

export default function OrderDetails() {
  const allCanteenUsers = useSelector((state) => state?.APIResponse?.allCanteenUsers)
  const allDepartments = useSelector((state) => state?.APIResponse?.AllDepartment)
  const allLocations = useSelector((state) => state?.APIResponse?.AllLocations)
  const AllCateringOrderStatus = useSelector((state) => state?.APIResponse?.AllCateringOrderStatus)

  // ============ Local States ============
  const [isLoading, setIsLoading] = useState(false)
  const [isSaveCanteenLoading, setIsSaveCanteenLoading] = useState(false)
  const [orderDetails, setOrderDetails] = useState({})
  const [cateringAllProduct, setCateringAllProduct] = useState([])
  const [allCompanyResponse, setAllCompanyResponse] = useState([])

  const [error, setError] = useState({ Status: false, Indexs: [] })

  const [editCanteenUsersStatus, setEditCanteenUsersStatus] = useState(false)
  const [deliveryOrderUsers, setDeliveryOrderUsers] = useState([])
  const [updatedOrderByName, setUpdatedOrderByName] = useState("")
  const [updatedOrderByEmail, setUpdatedOrderByEmail] = useState("")
  const [editOrderProductStatus, setEditOrderProductStatus] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState(0)
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState("Delivery")
  const [updatedInternalAttendees, setUpdatedInternalAttendees] = useState(0)
  const [updatedExternalAttendees, setUpdatedExternalAttendees] = useState(0)
  const [userSearch, setUserSearch] = useState("")
  const [searchInputCardStatus, setSearchInputCardStatus] = useState(false)
  const [colleaguesList, setColleaguesList] = useState([])
  const [updatedSharedComment, setUpdatedSharedComment] = useState("")
  const [updatedInternalComment, setUpdatedInternalComment] = useState("")
  const [updatedProductList, setUpdatedProductList] = useState([])
  const [invoiceInternal, setInvoiceInternal] = useState(true)
  const [invoiceExternalDetails, setInvoiceExternalDetails] = useState({
    CompanyName: "",
    Address: "",
    Zip: "",
    CVR: "",
    Att: "",
    Mail: ""
  })
  const [compnayQueryString, setCompnayQueryString] = useState("")
  const [inputDiscount, setInputDiscount] = useState("")
  const [inputAmount, setInputAmount] = useState("")
  const [disPercent, setDisPercent] = useState("")
  const [discountTotalDisplay, setDiscountTotalDisplay] = useState(false)
  const [discountAmountDisplay, setDiscountAmountDisplay] = useState(false)

  const meetingLocation = useMemo(() => {
    if (allLocations && allLocations?.length > 0 && orderDetails?.Meeting?.MeetingRooms && orderDetails?.Meeting?.MeetingRooms[0]?.Location) {
      return allLocations.find((d) => d?.Id === orderDetails?.Meeting?.MeetingRooms[0]?.Location?.Id)
    } else {
      return {}
    }
  }, [allLocations, orderDetails])

  const { orderId } = useParams()
  const dispatch = useDispatch()
  const costcenterRef = useRef()
  const deliveryRef = useRef()
  const orderBySearch = useRef()

  const updatedPrice = useCallback(() => {
    const total = updatedProductList && updatedProductList?.length > 0
      ? updatedProductList?.map((d) => d?.NumberOfItems * d?.Product?.Price)?.reduce((a, b) => a + b)
      : "00"
    return total
  }, [updatedProductList])

  // Discounted Price
  const discountTotal = useMemo(() => {
    let discountAmount = updatedPrice() * disPercent / 100
    let finalAmount = updatedPrice() - discountAmount

    return { amount: finalAmount.toFixed(2), discount: discountAmount === 0 ? "" : (discountAmount).toFixed(2) }
  }, [updatedPrice, disPercent])

  // Discounted Amount
  const discountAmount = useMemo(() => {
    let discountTotal = updatedPrice() - inputAmount
    let percent = Math.round((inputAmount * 100) / updatedPrice())
    setInputDiscount(
      percent === 0
        ? ""
        : percent
    )

    return { amount: discountTotal?.toFixed(2) }
  }, [updatedPrice, inputAmount])

  useEffect(() => {
    setInputAmount(discountTotal.discount === 0 ? "" : discountTotal?.discount)
  }, [discountTotal])

  const resetSearchInput = (cardStatus) => {
    setSearchInputCardStatus(cardStatus)
    setUserSearch("")
    setColleaguesList([])
  }

  const resetDeliveryGroupState = () => {
    setDeliveryOrderUsers(orderDetails?.CanteenUsers?.map((d) => d?.Id))
    setUpdatedOrderByName(orderDetails?.OrderedByName)
    setUpdatedOrderByEmail(orderDetails?.OrderedByEmail)
    resetSearchInput(false)
  }

  const resetUpdatedItemGroupState = (orderDetails) => {
    const updatedProdList = ([
      ...[...orderDetails?.ProductOrders]?.map((d) => ({
        ...d,
        DeliveryHour: !d?.DeliveryHour ? moment(orderDetails?.Meeting?.Start).format("HH") : d?.DeliveryHour,
        DeliveryMinute: !d?.DeliveryMinute ? moment(orderDetails?.Meeting?.Start).format("mm") : d?.DeliveryMinute,
      })),
      ...[...orderDetails?.ProductOtherOrders]?.map((d) => ({
        ...d,
        DeliveryHour: !d?.DeliveryHour ? moment(orderDetails?.Meeting?.Start).format("HH") : d?.DeliveryHour,
        DeliveryMinute: !d?.DeliveryMinute ? moment(orderDetails?.Meeting?.Start).format("mm") : d?.DeliveryMinute,
      }))
    ])

    setUpdatedProductList(updatedProdList.sort((a, b) => Number(`${a?.DeliveryHour}${a?.DeliveryMinute}`) - Number(`${b?.DeliveryHour}${b?.DeliveryMinute}`)))
    setSelectedDepartment(orderDetails?.Department?.Id)
    setSelectedDeliveryMethod(orderDetails?.DeliveryMethod === "Pickup" ? orderDetails?.DeliveryMethod : "Delivery")
    setUpdatedInternalAttendees(orderDetails?.NumberOfPeople)
    setUpdatedExternalAttendees(orderDetails?.ExternalAttendeesPeoples)
    setUpdatedSharedComment(orderDetails?.Comment !== null ? orderDetails?.Comment : "")
    setUpdatedInternalComment(orderDetails?.InternalComment !== null ? orderDetails?.InternalComment : "")
    setInvoiceInternal(orderDetails?.InvoiceInternal ? orderDetails?.InvoiceInternal : false)
    setInvoiceExternalDetails({
      CompanyName: orderDetails?.InvoiceExternalDetails?.CompanyName,
      Address: orderDetails?.InvoiceExternalDetails?.Address,
      Zip: orderDetails?.InvoiceExternalDetails?.Zip,
      CVR: orderDetails?.InvoiceExternalDetails?.CVR,
      Att: orderDetails?.InvoiceExternalDetails?.Att,
      Mail: orderDetails?.InvoiceExternalDetails?.Mail
    })
    setInputDiscount(orderDetails?.Discount === 0 ? "" : orderDetails?.Discount)
    setDisPercent(orderDetails?.Discount === 0 ? "" : orderDetails?.Discount)
  }

  const handleDeleteOreder = (data, i) => {
    setUpdatedProductList((prev) => prev?.filter((d) => d !== data))
  }

  // ============================================================================================
  const handleDeliveryTimeChange = (time, index) => {
    let arr = [...updatedProductList]

    setError((prev) => ({ ...prev, Indexs: prev?.Indexs?.filter((i) => i !== index) }))

    // /^(?:[01]\d|2[0-3]):[0-5]\d$/
    const checkLen0 = () => {
      if (time?.length === 0) {
        arr = arr?.map((p, i) => ({
          ...p,
          DeliveryHour: i === index ? "" : p?.DeliveryHour,
          DeliveryMinute: i === index ? "" : p?.DeliveryMinute
        }))
      }
    }
    const checkLen1 = () => {
      if (time?.length === 1) {
        if ((/^[0-2]$/).test(time)) {
          // position 1
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 1) : p?.DeliveryHour,
            DeliveryMinute: i === index ? "" : p?.DeliveryMinute
          }))
        } else {
          checkLen0()
        }
      }
    }
    const checkLen2 = () => {
      if (time?.length === 2) {
        if ((/^(?:[01]\d|2[0-3])$/).test(time)) {
          // position 2
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
            DeliveryMinute: i === index ? "" : p?.DeliveryMinute
          }))
        } else {
          checkLen1()
        }
      }
    }
    const checkLen4 = () => {
      if (time?.length === 4) {
        if ((/^(?:[01]\d|2[0-3]):[0-5]$/).test(time)) {
          // position 4
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
            DeliveryMinute: i === index ? time?.slice(3, 4) : p?.DeliveryMinute
          }))
        } else {
          checkLen2()
        }
      }
    }
    const checkLen5 = () => {
      if (time?.length === 5) {
        if ((/^(?:[01]\d|2[0-3]):[0-5]\d$/).test(time)) {
          // position 5
          arr = arr?.map((p, i) => ({
            ...p,
            DeliveryHour: i === index ? time?.slice(0, 2) : p?.DeliveryHour,
            DeliveryMinute: i === index ? time?.slice(3, 5) : p?.DeliveryMinute
          }))
        } else {
          checkLen4()
        }
      }
    }

    checkLen0()
    checkLen1()
    checkLen2()
    checkLen4()
    checkLen5()

    setUpdatedProductList(arr)
  }

  const handleOnBlur = (time, index) => {
    if (time.length === 1) {
      handleDeliveryTimeChange("0" + time + ":00", index)
    }
    if (time.length === 2) {
      handleDeliveryTimeChange(time + ":00", index)
    }
    if (time.length === 4) {
      handleDeliveryTimeChange(time?.slice(0, 2) + ":0" + time?.slice(3, 4), index)
    }
  }
  // ============================================================================================

  const getDetails = async (orderId) => {
    if (orderId) {
      setIsLoading(true)
      await getCateringOrder(orderId).then((orderResponse) => {
        if (typeof orderResponse !== "string" && orderResponse?.Id !== undefined && typeof orderResponse === "object") {
          const cateringData = ({
            ...orderResponse,
            ProductOrders: orderResponse?.ProductOrders?.map((d) => ({
              ...d,
              DeliveryHour: d?.DeliveryHour === null ? "" : d?.DeliveryHour < 10 ? `0${d?.DeliveryHour}` : `${d?.DeliveryHour}`,
              DeliveryMinute: d?.DeliveryMinute === null ? "" : d?.DeliveryMinute < 10 ? `0${d?.DeliveryMinute}` : `${d?.DeliveryMinute}`,
            })),
            ProductOtherOrders: orderResponse?.ProductOtherOrders?.map((d) => ({
              DeliveryHour: d?.DeliveryHour === null ? "" : d?.DeliveryHour < 10 ? `0${d?.DeliveryHour}` : `${d?.DeliveryHour}`,
              DeliveryMinute: d?.DeliveryMinute === null ? "" : d?.DeliveryMinute < 10 ? `0${d?.DeliveryMinute}` : `${d?.DeliveryMinute}`,
              NumberOfItems: d?.NumberOfItems,
              Product: { Id: d?.Id, Name: d?.Name, Notes: d?.Name, Price: d?.Price }
            }))
          })
          const sortProdTime = ({
            ...cateringData,
            ProductOrders: cateringData?.ProductOrders?.length > 0
              ? cateringData?.ProductOrders?.sort((a, b) => Number(`${a?.DeliveryHour}${a?.DeliveryMinute}`) - Number(`${b?.DeliveryHour}${b?.DeliveryMinute}`))
              : [],
            ProductOtherOrders: cateringData?.ProductOtherOrders?.length > 0
              ? cateringData?.ProductOtherOrders?.sort((a, b) => Number(`${a?.DeliveryHour}${a?.DeliveryMinute}`) - Number(`${b?.DeliveryHour}${b?.DeliveryMinute}`))
              : []
          })
          setOrderDetails(sortProdTime)
          GetCateringProduct(orderResponse)
          setDeliveryOrderUsers(orderResponse?.CanteenUsers?.map((d) => d?.Id))
          resetUpdatedItemGroupState(orderResponse)
          setUpdatedOrderByName(orderResponse?.OrderedByName ? orderResponse?.OrderedByName : "")
          setUpdatedOrderByEmail(orderResponse?.OrderedByEmail ? orderResponse?.OrderedByEmail : "")
        } else if (typeof orderResponse === "string") {
          toast.error(orderResponse)
        } else if (orderResponse?.ExceptionMessage) {
          toast.error(orderResponse?.ExceptionMessage)
        }
      }).catch((error) => {
        console.warn(error)
      }).finally(() => {
        setIsLoading(false)
      })

      return
    } else {
      setOrderDetails({})
      toast.error("OrderId is Missing")
    }
  }

  const GetCateringProduct = (response) => {
    const data = {
      locationId: response?.Meeting?.MeetingRooms?.length > 0 ? response?.Meeting?.MeetingRooms[0]?.Location?.Id : response?.Location?.Id,
      date: moment(response?.Meeting?.Start).format("YYYY-MM-DD"),
      startTimeMeetingHour: response?.Meeting?.Start ? moment(response?.Meeting?.Start).format("HH") : response?.DeliveryHour,
      startTimeMeetingMinute: response?.Meeting?.Start ? moment(response?.Meeting?.Start).format("mm") : response?.DeliveryMinute,
      endTimeMeetingHour: response?.Meeting?.End ? moment(response?.Meeting?.End).format("HH") : response?.DeliveryHour,
      endTimeMeetingMinute: response?.Meeting?.End ? moment(response?.Meeting?.End).format("mm") : response?.DeliveryMinute,
    }

    // if (data?.locationId && data?.date && data?.startTimeMeetingHour && data?.startTimeMeetingMinute && data?.endTimeMeetingHour && data?.endTimeMeetingMinute) {
    getCateringProduct(data).then((resp) => {
      let arr = []
      if (resp?.length > 0) {
        for (let i = 0; i < resp.length; i++) {
          for (let j = 0; j < resp[i].Products.length; j++) {
            arr.push({
              Name: resp[i].Products[j].Name,
              Id: resp[i].Products[j].Id,
              Notes: resp[i].Products[j].Notes,
              Price: resp[i].Products[j].Price,
            })
          }
        }
      }
      setCateringAllProduct(arr)
    }).catch((error) => {
      setCateringAllProduct([])
      toast.error(error)
    })
    // }
  }

  const UpdateCateringOrderStatus = (statusId) => {
    setIsLoading(true)
    updateCateringOrderStatus(orderDetails?.Id, statusId).then(async (response) => {
      if (!response) {
        await getDetails(orderId)
        toast.success("Order Updated Successfully.")
      } else {
        toast.error("Looks like an Error!")
      }
    }).catch((error) => {
      console.log('error', error)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const scrollToDiv = () => {
    var prodList = document.getElementById("prodList");
    prodList.scrollIntoView();
  }

  const SaveCanteenOrder = () => {
    console.log('save')
    setIsSaveCanteenLoading(true)

    const validationForTime = updatedProductList?.map((p, i) => {
      const time = p?.DeliveryMinute ? p?.DeliveryHour + ":" + p?.DeliveryMinute : p?.DeliveryHour
      return {
        ...p,
        isValid: time ? (
          moment(moment(time, "HH:mm")._d).isSame(moment(moment(orderDetails?.Meeting?.Start).format("HH:mm"), "HH:mm")._d) ||
          moment(moment(time, "HH:mm")._d).isSame(moment(moment(orderDetails?.Meeting?.End).format("HH:mm"), "HH:mm")._d) ||
          moment(moment(time, "HH:mm")._d).isBetween(moment(moment(orderDetails?.Meeting?.Start).format("HH:mm"), "HH:mm")._d, moment(moment(orderDetails?.Meeting?.End).format("HH:mm"), "HH:mm")._d)
        ) : true
      }
    })
    const inValidOrders = validationForTime?.map((d, i) => ({ ...d, Index: i }))?.filter((order) => !order?.isValid)

    if (inValidOrders?.length === 0) {
      const requestPayload = {
        "Catering": {
          "DepartmentId": editOrderProductStatus ? selectedDepartment : orderDetails?.Department?.Id,
          "InvoiceInternal": invoiceInternal,
          "InvoiceExternalDetails": invoiceInternal === false ? invoiceExternalDetails : null,
          "Discount": inputDiscount,
          "Responsible": deliveryOrderUsers,
          "NumberOfPeople": editOrderProductStatus
            ? updatedInternalAttendees
            : orderDetails?.NumberOfPeople,
          "ExternalAttendeesPeoples": editOrderProductStatus
            ? updatedExternalAttendees
            : orderDetails?.ExternalAttendeesPeoples,
          "Products": editOrderProductStatus
            ? updatedProductList.filter((data) => cateringAllProduct.filter((d) => d?.Id === data?.Product?.Id).length !== 0 && data?.NumberOfItems > 0)?.map((d) => {
              return {
                ProductId: d?.Product.Id,
                NumberOfItems: d?.NumberOfItems,
                DeliveryHour: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryHour,
                DeliveryMinute: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryMinute
              }
            })
            : [...orderDetails]?.ProductOrders?.map((d) => {
              return {
                ProductId: d.Product.Id,
                NumberOfItems: d.NumberOfItems,
                DeliveryHour: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryHour,
                DeliveryMinute: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryMinute
              }
            }),
          "ProductsOther": editOrderProductStatus
            ? updatedProductList.filter((data) => data?.Product?.Name.trim() !== "" && cateringAllProduct.filter((d) => d?.Id === data?.Product?.Id).length === 0 && data?.NumberOfItems !== "" && data?.NumberOfItems > 0)?.map((d) => {
              return {
                Id: d?.Product?.Id,
                Name: d?.Product?.Name,
                NumberOfItems: d?.NumberOfItems,
                Price: d?.Product?.Price,
                DeliveryHour: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryHour,
                DeliveryMinute: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryMinute
              }
            })
            : [...orderDetails]?.ProductOtherOrders?.map((d) => {
              return {
                Id: d?.Id,
                Name: d?.Name,
                NumberOfItems: d?.NumberOfItems,
                Price: d?.Price,
                DeliveryHour: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryHour,
                DeliveryMinute: selectedDeliveryMethod === "Pickup" ? "" : d?.DeliveryMinute
              }
            }),
          "Comment": editOrderProductStatus ? updatedSharedComment : orderDetails?.Comment,
          "InternalComment": editOrderProductStatus ? updatedInternalComment : orderDetails?.InternalComment,
          "DeliveryMethod": selectedDeliveryMethod,
          "OrderStatusId": orderDetails?.OrderStatus?.Id,
          "OrderedByName": updatedOrderByName,
          "OrderedByEmail": updatedOrderByEmail,
        },
        "MyDeskMeetingId": orderDetails?.Meeting?.Id,
        "MeetingroomId": orderDetails?.Meeting?.MeetingRooms[0]?.Id,
        "ExchangeMeetingId": orderDetails?.Meeting?.ExchangeId,
        "ExchangeICalUId": orderDetails?.Meeting?.ExchangeICalUId,
      }

      if (
        (invoiceInternal ?
          requestPayload?.Catering?.DepartmentId
          : (
            requestPayload?.Catering?.InvoiceExternalDetails?.CompanyName?.trim() ?
              ((
                requestPayload?.Catering?.InvoiceExternalDetails?.CVR ?
                  (requestPayload?.Catering?.InvoiceExternalDetails?.CVR?.length === 13 || requestPayload?.Catering?.InvoiceExternalDetails?.CVR?.length === 8) :
                  true
              )
                &&
                (requestPayload?.Catering?.InvoiceExternalDetails?.Mail ?
                  emailRegex.test(requestPayload?.Catering?.InvoiceExternalDetails?.Mail) :
                  true))
              : true
          )
        ) &&
        (!editCanteenUsersStatus
          ? (requestPayload?.Catering?.Products.length !== 0 || requestPayload?.Catering?.ProductsOther?.length !== 0)
          : true
        ) &&
        (requestPayload?.Catering?.NumberOfPeople + requestPayload?.Catering?.ExternalAttendeesPeoples > 0 && requestPayload?.Catering?.OrderedByName !== "" && requestPayload?.Catering?.OrderedByEmail !== "")
      ) {
        createUpdateOrder(requestPayload, orderDetails?.Meeting?.Id).then((res) => {
          if (!res) {
            toast.success("Order Updated Successfully.")
            getDetails(orderId)
            setEditCanteenUsersStatus(false)
            setEditOrderProductStatus(false)
          } else {
            toast.error(res?.Message)
          }
        }).catch((error) => {
          toast.error(error)
        }).finally(() => {
          setIsSaveCanteenLoading(false)
        })
      } else {
        setIsSaveCanteenLoading(false)
        if (!editCanteenUsersStatus ? (requestPayload?.Catering?.Products?.length === 0 && requestPayload?.Catering?.ProductsOther?.length === 0) : false) {
          toast.error("Must have one product!")
        } else if (invoiceInternal && !requestPayload?.Catering?.DepartmentId) {
          toast.error("Costcenter is required!")
        } else if (!invoiceInternal && requestPayload?.Catering?.InvoiceExternalDetails?.CVR && requestPayload?.Catering?.InvoiceExternalDetails?.CVR?.length !== 8 && requestPayload?.Catering?.InvoiceExternalDetails?.CVR?.length !== 13) {
          toast.error("Please Enter CVR/EAN number must be 8/13 digit long !")
        } else if (!invoiceInternal && requestPayload?.Catering?.InvoiceExternalDetails?.Mail && !emailRegex.test(requestPayload?.Catering?.InvoiceExternalDetails?.Mail)) {
          toast.error("Input valid mail!")
        } else if (!invoiceInternal && !requestPayload?.Catering?.InvoiceExternalDetails?.CompanyName?.trim()) {
          toast.error("External invoice company name required!")
        } else if (requestPayload?.Catering?.NumberOfPeople + requestPayload?.Catering?.ExternalAttendeesPeoples <= 0) {
          toast.error("Attendees is required!")
        } else if (requestPayload?.Catering?.OrderedByName === "" && requestPayload?.Catering?.OrderedByEmail === "") {
          toast.error("Order By is required!")
        }
      }
    } else {
      setError({ Status: true, Indexs: inValidOrders?.map((order) => order?.Index) })
      scrollToDiv()
      setIsSaveCanteenLoading(false)
      toast?.error(`Please enter valid time between ${moment(orderDetails?.Meeting?.Start).format("HH:mm")} - ${moment(orderDetails?.Meeting?.End).format("HH:mm")} !`)
    }
  }

  const GetCateringOrderStatus = async () => {
    setIsLoading(true)

    if (AllCateringOrderStatus.length === 0) {
      await getCateringOrderStatus().then((response) => {
        dispatch(changeAllCateringOrderStatusValue(response))
      }).catch((error) => {
        dispatch(changeAllCateringOrderStatusValue([]))
        toast.error(error)
        setIsLoading(false)
      })
    }
  }


  useEffect(() => {
    userSearch?.length >= 3 &&
      searchColleague(userSearch).then((response) => {
        setColleaguesList(response?.length > 0 ? response : [])
      }).catch((error) => {
        setColleaguesList([])
        console.warn(error)
      })
  }, [userSearch])

  // Get Order Details
  useEffect(() => {
    if (orderId) {
      getDetails(orderId)
    } else {
      setOrderDetails({})
      toast.error("OrderId is Missing")
    }
  }, [orderId])

  useEffect(() => {
    GetCateringOrderStatus()
    if (dispatch) {
      getAdminLocation().then((response) => {
        dispatch(changeAllLocationValue(response))
      }).catch((error) => {
        dispatch(changeAllLocationValue([]))
        toast.error(error)
      })

      getDepartment().then((response) => {
        dispatch(changeAllDepartmentValue(response))
      }).catch((error) => {
        dispatch([])
        console.warn(error)
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (invoiceInternal === true) {
      setInvoiceExternalDetails({
        CompanyName: "",
        Address: "",
        Zip: "",
        CVR: "",
        Att: "",
        Mail: ""
      })
    }
  }, [invoiceInternal])

  // Company Query Search
  useEffect(() => {
    let subscribed = true

    const timeout = setTimeout(() => {

      if (compnayQueryString?.trim()) {
        getAllCompanyNameQuery(compnayQueryString).then((response) => {
          if (subscribed) {
            response?.length > 0 && setAllCompanyResponse(response)
          }
        }).catch((error) => {
          if (subscribed) {
            console.error(error)
          }
        }).finally(() => {
          if (subscribed) {
          }
        })
      }
    }, 1000);

    return () => {
      subscribed = false
      clearTimeout(timeout)
    }
  }, [compnayQueryString])

  return (
    <>
      {(isLoading || isSaveCanteenLoading) && <Loader />}

      <button onClick={() => window.history.back()} className="navigation-back-arrow">
        <i className="fa fa-arrow-left" />
      </button>

      {typeof orderDetails === "object" && Object.keys(orderDetails).length > 0 && (
        <>
          <section className="section mb-2">
            <div className="row my-4">
              <div className="col-9">
                <div className="pagetitle">
                  <h1 className="mt-2">{orderDetails?.Meeting?.Id ? orderDetails?.Meeting?.Title : "A Pickup Order"}</h1>
                </div>
              </div>
              <div className="col-3 text-end">
                <button
                  onClick={() => {
                    setEditCanteenUsersStatus(!editCanteenUsersStatus)
                    setEditOrderProductStatus(false)
                    !editCanteenUsersStatus && resetDeliveryGroupState()
                  }}
                  className="btn btn-secondary btn-icon  btn-icon-md"
                >
                  <img alt="icon-close" src={editCanteenUsersStatus ? iconClose : iconEdit} />
                </button>
              </div>
            </div>

            <div className="card-content mb-4">
              <div className="row list mt-3 ">
                <div className="col-6 txt-md mb-4 d-flex">
                  <div className="icon-md icon-md-bg d-inline-block">
                    <img alt="icon-pin" src={iconPin} />
                  </div>
                  <div className="userInfo d-inline-block">
                    <span className="txt-sm txt-gray">Location</span>
                    <span>
                      {orderDetails?.Meeting?.MeetingRooms[0] && orderDetails?.Meeting?.MeetingRooms[0]?.Location?.Name ?
                        orderDetails?.Meeting?.MeetingRooms[0]?.Location?.Name + " (" + orderDetails?.Meeting?.MeetingRooms[0]?.Name + ")"
                        : orderDetails?.Location?.Id ? orderDetails?.Location?.Name : "- -"
                      }
                    </span>
                  </div>
                </div>

                {orderDetails?.Meeting?.Id && (
                  <div className="col-6 txt-md mb-4">
                    <div className="icon-md icon-md-bg d-inline-block">
                      <img alt="icon-users" src={iconUsers} />
                    </div>
                    <div className="userInfo d-inline-block persons">
                      <span className="txt-sm txt-gray">Participants</span>
                      <span>{orderDetails?.NumberOfPeople + orderDetails?.ExternalAttendeesPeoples} Persons</span>
                    </div>
                  </div>
                )}

                {orderDetails?.Meeting?.Id && (
                  <div className="col-6 txt-md mb-4">
                    <div className="icon-md icon-md-bg d-inline-block">
                      <img alt="icon-clock" src={iconClock} />
                    </div>
                    <div className="userInfo d-inline-block">
                      <span className="txt-sm txt-gray">Meeting time</span>
                      <span>{moment(orderDetails?.Meeting?.Start).format("HH:mm")} - {moment(orderDetails?.Meeting?.End).format("HH:mm")}</span>
                    </div>
                  </div>
                )}

                <div className="col-6 txt-md mb-4 d-flex">
                  <div className="icon-md icon-md-bg d-inline-block">
                    <img alt="icon-user" src={iconUser} />
                  </div>
                  <div className="userInfo d-inline-block" style={{ flex: 1 }}>
                    {editCanteenUsersStatus ? (
                      <div onClick={() => searchInputCardStatus && orderBySearch.current.focus()} className="form-control custom-search-group hover">
                        <span className="text-md fs-6" onClick={() => resetSearchInput(!searchInputCardStatus)}>{updatedOrderByName}</span>
                        {searchInputCardStatus &&
                          <div className="search-wrapper">
                            <input
                              value={userSearch} ref={orderBySearch}
                              onChange={(e) => setUserSearch(e.target.value)}
                              className="form-control search-input" placeholder="search"
                            />
                            {userSearch.length < 3 && <div className="item-card"><p className="txt-md">Please enter 3 or more characters</p></div>}
                            {colleaguesList?.map((data, i) =>
                              <div
                                key={i} className="item-card item-card-hover"
                                onClick={() => {
                                  setUpdatedOrderByName(data.Name)
                                  setUpdatedOrderByEmail(data.UPN)
                                  resetSearchInput(false)
                                }}
                              >
                                <b className="txt-md mb-0">{data?.Name}</b>
                                <p className="txt-sm mb-0">{data?.UPN}</p>
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    ) : (
                      <>
                        <span className="txt-sm txt-gray">Ordered by Name</span>
                        <span className="ellipsis">{orderDetails?.OrderedByName ? orderDetails?.OrderedByName : ""}</span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-6 txt-md mb-4">
                  <div className="icon-md icon-md-bg d-inline-block">
                    <img
                      src={orderDetails?.OrderStatus?.Id === 1
                        ? iconNotStarted
                        : orderDetails?.OrderStatus?.Id === 2
                          ? iconInProgress
                          : iconCompleted
                      }
                      alt="icon-clock"
                    />
                  </div>
                  <div className="userInfo d-inline-block">
                    <span className="txt-sm txt-gray">Order Status</span>
                    <span className="ellipsis">{orderDetails?.OrderStatus?.Name}</span>
                  </div>
                </div>

                <div className="col-6 txt-md mb-4 d-flex">
                  <div className="icon-md icon-md-bg d-inline-block">
                    <img alt="icon-user" src={iconUser} />
                  </div>
                  <div className="userInfo d-inline-block overflow-hidden">
                    <span className="txt-sm txt-gray">Ordered by Email</span>
                    <span className="ellipsis">{editCanteenUsersStatus ? updatedOrderByEmail : orderDetails?.OrderedByEmail}</span>
                  </div>
                </div>

                {orderDetails?.Meeting?.Id && (
                  <div className="col-6 txt-md d-flex">
                    <div className="icon-md icon-md-bg d-inline-block">
                      <img src={iconUsers} alt="Profile" />
                    </div>
                    {!editCanteenUsersStatus && (
                      <div className="userInfo d-inline-block" style={{ flex: 1 }}>
                        <span className="txt-sm txt-gray">Delivered by</span>
                        <span>
                          {orderDetails?.CanteenUsers?.length > 0 ?
                            orderDetails?.CanteenUsers?.map((deliver, i) =>
                              i !== 0 && i !== orderDetails?.CanteenUsers?.length ? `, ${deliver.Name}`
                                : deliver?.Name
                            )
                            : "- -"
                          }
                        </span>
                      </div>
                    )}
                    {editCanteenUsersStatus &&
                      <div onClick={() => resetSearchInput(false)} className="userInfo d-inline-block" style={{ flex: 1 }}>
                        <Select
                          isMulti
                          value={orderDetails?.CanteenUsers?.map((d) => ({ value: d?.Id, label: d.Name }))}
                          options={allCanteenUsers?.map((d) => ({ value: d?.Id, label: d.Name }))}
                          className="form-control p-0" placeholder="Select Persons"
                          onChange={(e) => setDeliveryOrderUsers(e.map((d) => Number(d.value)))}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: "none",
                              boxShadow: "none",
                              borderRadius: 8,
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                            ClearIndicator: () => null,
                          }}
                        />
                      </div>
                    }
                  </div>
                )}

                <div className="col-6 txt-md mb-4">
                  <div className="icon-md icon-md-bg d-inline-block">
                    <img src={iconOrderNumber} style={{ padding: 6 }} alt="icon-order-number" />
                  </div>
                  <div className="userInfo d-inline-block">
                    <span className="txt-sm txt-gray">Order Number</span>
                    <span>{orderDetails?.OrderNumber}</span>
                  </div>
                </div>

                {editCanteenUsersStatus && (
                  <div className="col-12 mt-3">
                    <button onClick={() => SaveCanteenOrder()} className="btn btn-primary mb-2 btn-lg btn-full btnOuterSave">
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="card card-revert" id="prodList">
              <div className="row mb-3 mt-2">
                <div className="col-9">
                  <div className="pagetitle mt-2">
                    <h2>Orders</h2>
                  </div>
                </div>
                <div className="col-3 text-end">
                  <button
                    type="button"
                    className="btn btn-secondary btn-icon  btn-icon-lg"
                    onClick={() => {
                      setEditOrderProductStatus(!editOrderProductStatus)
                      setEditCanteenUsersStatus(false)
                      resetUpdatedItemGroupState(orderDetails)
                    }}
                  >
                    <img src={editOrderProductStatus ? iconClose : iconEdit} alt="icon-edit" />
                  </button>
                </div>
              </div>

              {/* ============================================================================================ */}
              {/* ==================================== Static Order Details ========+========================= */}
              {/* ============================================================================================ */}
              {!editOrderProductStatus && (
                <div className="table-responsive table-custom">
                  <table className="table table-hover valignM">
                    <tbody>
                      {[...orderDetails?.ProductOrders, ...orderDetails?.ProductOtherOrders]?.length > 0 ? (
                        [...orderDetails?.ProductOrders, ...orderDetails?.ProductOtherOrders]?.sort((a, b) =>
                          Number(`${a?.DeliveryHour}${a?.DeliveryMinute}`) - Number(`${b?.DeliveryHour}${b?.DeliveryMinute}`)
                        )?.map((order, i) =>
                          <tr key={i}>
                            <td className="position-relative">
                              <span className="txt500">{order?.Product?.Name}</span>
                              {order?.Product?.Notes && (
                                <>
                                  <i className="fa fa-info-circle position-absolute" style={{ right: "14px", top: "16px" }} data-tooltip-place="top" data-tooltip-id="my-tooltip" data-tooltip-content={order.Product?.Notes} />
                                  <Tooltip id="my-tooltip" />
                                </>
                              )}
                            </td>
                            <td className="text-center">
                              <span className="txt500">
                                {typeof order?.DeliveryHour === "string" && typeof order?.DeliveryMinute === "string"
                                  ? order?.DeliveryHour && order?.DeliveryMinute && moment(`${order?.DeliveryHour}:${order?.DeliveryMinute}`, "HH:mm").format("HH:mm")
                                  : ""
                                }
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="txt500">{order?.NumberOfItems}</span>
                              &nbsp;
                              <span className="txt-gray">
                                X&nbsp;{order.Product?.Price}&nbsp;{meetingLocation?.Currency === "" ? "Dkk" : meetingLocation?.Currency}
                              </span>
                            </td>
                            <td>
                              <span className="txt500">
                                {order?.NumberOfItems * order.Product?.Price}&nbsp;{meetingLocation?.Currency === "" ? "Dkk" : meetingLocation?.Currency}
                              </span>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="4">
                            <span className="txt-gray d-block">No Orders</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {/* ====================================================================================== */}
              {/* ======================================= Edit Product ================================= */}
              {/* ====================================================================================== */}
              {editOrderProductStatus && (
                <div className="table-responsive table-custom edit">
                  <table className="table table-hover valignM ">
                    <tbody>
                      {updatedProductList?.length > 0 ? (
                        updatedProductList?.map((data, i) =>
                          <tr key={i}>
                            {/* Product */}
                            <td className="position-relative">
                              <CreatableSelect
                                isClearable
                                placeholder="Select Product"
                                value={data?.Product?.Name && [{ value: data?.Product?.Id, label: data?.Product?.Name }]}
                                onChange={(e) => {
                                  if (e) {
                                    setUpdatedProductList((prev) =>
                                      prev?.map((data, j) => ({
                                        DeliveryHour: data?.DeliveryHour,
                                        DeliveryMinute: data?.DeliveryMinute,
                                        NumberOfItems: data.NumberOfItems,
                                        Product: i !== j ?
                                          data?.Product
                                          :
                                          cateringAllProduct?.find((d) => d?.Id === e?.value)?.Id ?
                                            cateringAllProduct?.find((d) => d?.Id === e?.value)
                                            :
                                            { Id: 0, Name: e?.value, Notes: e?.value, Price: 0 },

                                      })
                                      )
                                    )
                                  }
                                }}
                                options={cateringAllProduct?.map((d) => ({ value: d?.Id, label: d?.Name }))}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    border: "none",
                                    boxShadow: "none",
                                    padding: "6px 8px",
                                    borderRadius: 8,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                  }),
                                }}
                                components={{
                                  IndicatorSeparator: () => null,
                                  DropdownIndicator: () => null,
                                  ClearIndicator: () => null,
                                }}
                              />
                              {data?.Product?.Name && cateringAllProduct && cateringAllProduct.filter((d) => d?.Id === data?.Product?.Id).length > 0 &&
                                <>
                                  <i
                                    className="fa fa-info-circle position-absolute tool-tip"
                                    data-tooltip-place="top" data-tooltip-id="my-tooltip"
                                    data-tooltip-content={cateringAllProduct?.find((d) => d?.Id === data?.Product?.Id)?.Notes}
                                  />
                                  <Tooltip id="my-tooltip" />
                                </>
                              }
                            </td>

                            {/* Delivery Time */}
                            {selectedDeliveryMethod === "Delivery" && (
                              <td className="dlvryTime-td">
                                <InputMask
                                  mask="HH:mm"
                                  onBlur={(e) => handleOnBlur(e.target.value, i)}
                                  replacement={{ H: /\d/, m: /\d/ }}
                                  value={(() => {
                                    const time = updatedProductList?.find((prod, index) => index === i)
                                    return time?.DeliveryMinute ? time?.DeliveryHour + ":" + time?.DeliveryMinute : time?.DeliveryHour
                                  })()}
                                  onChange={(e) => handleDeliveryTimeChange(e.target.value, i)}
                                  separate
                                  className="form-control text-center"
                                  placeholder="HH:mm"
                                  style={{ border: error?.Status && error?.Indexs?.some((prod) => prod === i) && "1px solid red" }}
                                />
                              </td>
                            )}

                            {/* Qty */}
                            <td className="qty-td">
                              <input
                                type="number" value={data?.NumberOfItems}
                                onChange={(e) => {
                                  if (Number.isInteger(Number(e.target.value)) && Number(e.target.value) > -1) {
                                    setUpdatedProductList((prev) =>
                                      prev?.map((data, j) => ({
                                        DeliveryHour: data?.DeliveryHour,
                                        DeliveryMinute: data?.DeliveryMinute,
                                        NumberOfItems: i !== j
                                          ? data?.NumberOfItems
                                          : Number(e.target.value),
                                        Product: data?.Product,
                                      }))
                                    )
                                  }
                                }}
                                min={0} max={100}
                                className="form-control text-center" placeholder="Qty."
                              />
                            </td>

                            {/* Price */}
                            <td className="price-td">
                              <input
                                type="text"
                                className="form-control text-center"
                                placeholder="Price"
                                value={data?.Product?.Price}
                                onChange={(e) => setUpdatedProductList((prev) =>
                                  prev?.map((data, j) => ({
                                    DeliveryHour: data?.DeliveryHour,
                                    DeliveryMinute: data?.DeliveryMinute,
                                    NumberOfItems: data?.NumberOfItems,
                                    Product: i !== j
                                      ? data.Product
                                      : {
                                        Id: data?.Product?.Id, Name: data?.Product?.Name,
                                        Notes: data?.Product?.Notes,
                                        Price: Number.isInteger(Number(e.target.value))
                                          ? Number(e.target.value)
                                          : data?.Product?.Price
                                      },
                                  }))
                                )}
                                disabled={cateringAllProduct?.filter((d) => d?.Id === data?.Product?.Id)?.length > 0}
                              />
                            </td>

                            {/* Delete Btn */}
                            <td className="dlt-td">
                              <button
                                type="button"
                                className="btn btn-secondary btn-icon"
                                onClick={() => handleDeleteOreder(data, i)}
                              >
                                <img alt="icon-trash" src={iconTrash} />
                              </button>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="4">
                            <span className="txt-gray d-block no-order" id="Noorders" >No Orders</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="discount d-flex justify-content-end">
                    <div className="col-8 p-3 d-flex justify-content-end align-items-center">
                      <span className="txt500">Discount</span>
                    </div>

                    <div className="col-4 d-flex justify-content-end align-items-center">
                      {/* Input Discount */}
                      <div className="input-group">
                        <input
                          min={0} max={100}
                          value={inputDiscount}
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 100) {
                              setInputDiscount(e.target.value === 0 ? "" : e.target.value)
                              setDisPercent(e.target.value === 0 ? "" : e.target.value)
                              setDiscountTotalDisplay(true)
                              setDiscountAmountDisplay(false)
                            }
                          }}
                          type="number"
                          className="discount-input form-control form-control-sm text-center"
                          placeholder="Discount"
                          style={{ borderRight: "none", boxShadow: "none" }}
                        />
                        <span className="input-group-text" style={{ backgroundColor: "white", border: "1px solid #e8ecef" }}><i className="fa-solid fa-percent" /></span>
                      </div>

                      {/* Discount Amount */}
                      <div className="input-group">
                        <input
                          type="number"
                          value={inputAmount}
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= updatedPrice()) {
                              setInputAmount(e.target.value === 0 ? "" : e.target.value)
                              setDiscountAmountDisplay(true)
                              setDiscountTotalDisplay(false)
                            }
                          }}
                          className="discount-amount form-control form-control-sm text-center"
                          placeholder="Amount"
                          style={{ borderRight: "none", boxShadow: "none" }}
                        />
                        <span className="input-group-text" style={{ backgroundColor: "white", border: "1px solid #e8ecef" }}>Kr</span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <div className="col-7 p-3 d-flex justify-content-end align-items-center">
                      <span className="txt500">Total</span>
                    </div>
                    {/* Input Discount */}
                    <div className="col-2">
                    </div>
                    {/* Discount Amount */}
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <span className="txt500">
                        {discountTotalDisplay
                          ? isNaN(discountTotal.amount)
                            ? 0
                            : discountTotal.amount
                          : discountAmountDisplay
                            ? isNaN(discountAmount.amount)
                              ? 0
                              : discountAmount.amount
                            : discountTotal.amount
                        }&nbsp;
                        {meetingLocation?.Currency === "" ? "Dkk" : meetingLocation?.Currency}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* ============================================================================================ */}
              {/* ================================ Total Price And Add Item Button =========================== */}
              {/* ============================================================================================ */}
              <div className="row mb-2 mt-2">
                <div className="col-9">
                  {!editOrderProductStatus &&
                    <div className="pagetitle mt-2">
                      <h2 className="txt-black txtR">
                        Total <span>{editOrderProductStatus ? updatedPrice()?.toFixed(2) : (orderDetails?.TotalPrice)?.toFixed(2)}</span>&nbsp;{meetingLocation?.Currency ?? "Dkk"}
                      </h2>
                    </div>
                  }
                </div>

                {editOrderProductStatus && (
                  <div className="col-3 text-end">
                    <button
                      type="button"
                      className="btn btn-secondary btn-icon btn-icon-lg"
                      id="addRow"
                      onClick={() => {
                        setUpdatedProductList((prev) => [
                          ...prev,
                          {
                            Product: {
                              Id: 0,
                              Name: "",
                              Notes: "",
                              Price: 0
                            },
                            NumberOfItems: "",
                            DeliveryHour: moment(orderDetails?.Meeting?.Start).format("HH"),
                            DeliveryMinute: moment(orderDetails?.Meeting?.Start).format("mm")
                          }
                        ])
                      }}
                    >
                      <img alt="icon-add" src={iconAdd} />
                    </button>
                  </div>
                )}
              </div>

              {/* ============================================================================================ */}
              {/* ===================================== Display Order Details ================================ */}
              {/* ============================================================================================ */}
              {!editOrderProductStatus && (
                <div className="card-content">
                  <div className="row list mt-3">
                    <div className="col-3 txt-md mt-3">
                      <span className="txt500">Costcenter/External customer</span>
                    </div>
                    <div className="col-9 txt-md mt-2">
                      {orderDetails?.InvoiceInternal === true ? (
                        <div className="userInfo d-inline-block departmentName mt-3">
                          <span>
                            {orderDetails?.Department?.Name}
                            {" ("}{orderDetails?.Department?.ManagerName}{")"}
                          </span>
                        </div>
                      ) : (
                        <div className="col-12 border p-2 rounded" style={{ width: "fit-content" }}>
                          {/* <div className="p-2"> */}
                          <label className="">
                            CompanyName : &nbsp;
                            {orderDetails?.InvoiceExternalDetails?.CompanyName}
                          </label>
                          {orderDetails?.InvoiceExternalDetails?.Address &&
                            (<label className="ms-3">
                              Address : &nbsp;
                              {orderDetails?.InvoiceExternalDetails?.Address}
                            </label>
                            )}

                          {orderDetails?.InvoiceExternalDetails?.Zip &&
                            (<label className="ms-3">
                              Zip : &nbsp;
                              {orderDetails?.InvoiceExternalDetails?.Zip}
                            </label>
                            )}

                          {orderDetails?.InvoiceExternalDetails?.CVR && (
                            <label className="ms-3">
                              CVR/EAN : &nbsp;
                              {orderDetails?.InvoiceExternalDetails?.CVR}
                            </label>
                          )}

                          {orderDetails?.InvoiceExternalDetails?.Att && (
                            <label className="ms-3">
                              Att : &nbsp;
                              {orderDetails?.InvoiceExternalDetails?.Att}
                            </label>
                          )}

                          {orderDetails?.InvoiceExternalDetails?.Mail && (
                            <label className="ms-3">
                              Mail : &nbsp;
                              {orderDetails?.InvoiceExternalDetails?.Mail}
                            </label>
                          )}

                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row list mt-3">
                    <div className="col-3 txt-md mt-3">
                      <span className="txt500">Delivery Method</span>
                    </div>
                    <div className="col-9 txt-md mb-2">
                      <div className="userInfo d-inline-block mt-3">
                        <span>{orderDetails?.DeliveryMethod}</span>
                      </div>
                    </div>
                  </div>

                  {orderDetails?.Meeting?.Id && <div className="row list mt-3 ">
                    <div className="col-3 txt-md mb-2 mt-2">
                      <span className="txt500">Internal</span>
                    </div>
                    <div className="col-3 txt-md mb-2 mt-2">
                      <span className="px-2">{orderDetails?.NumberOfPeople}</span>
                    </div>
                    <div className="col-3 txt-md mb-2 mt-2">
                      <span className="txt500">External</span>
                    </div>
                    <div className="col-3 txt-md mb-2 mt-2">
                      <span>{orderDetails?.ExternalAttendeesPeoples}</span>
                    </div>
                  </div>}

                  <div className="row list mt-3 ">
                    <div className="txt-md mb-2 mt-3">
                      <span className="txt500">Shared Comment</span>
                      <div className="txt-md mb-2 mt-3">
                        <span>
                          <pre style={{ fontFamily: "Barlow, serif", fontWeight: "500", whiteSpace: "break-spaces" }}>
                            {orderDetails?.Comment}
                          </pre>
                        </span>
                      </div>
                    </div>
                    <div className="txt-md mb-2 mt-3">
                      <span className="txt500">Internal Comment</span>
                      <div className="txt-md mb-2 mt-3">
                        <span>
                          <pre style={{ fontFamily: "Barlow, serif", fontWeight: "500", whiteSpace: "break-spaces" }}>
                            {orderDetails?.InternalComment}
                          </pre>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* ============================================================================================ */}
            {/* ====================================== Edit Order Details  ================================= */}
            {/* ============================================================================================ */}
            {editOrderProductStatus && (
              <div className="card-content">
                {/* Costcenter */}
                <div className="row list mt-3 costcenter">
                  <div className="col-sm-12 col-md-12 col-lg-3 col-xl-xl-3 txt-md mb-2">
                    <span className="txt500" style={{ wordBreak: "break-word" }}>Costcenter/External customer</span>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-9 col-xl-xl-9 txt-md mb-2">
                    <div className="d-flex mb-3">
                      <div className="form-check d-flex align-items-center me-3">
                        <div className="d-flex align-items-center">
                          <label className="form-check-label me-2" htmlFor="internal">
                            <span className="txt500">Internal</span>
                          </label>
                          <input
                            type="radio"
                            checked={invoiceInternal === true}
                            onChange={() => setInvoiceInternal(true)}
                            name="costcenter"
                            id="internal"
                          />
                        </div>
                      </div>
                      <div className="form-check d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <label className="form-check-label me-2" htmlFor="external">
                            <span className="txt500">External</span>
                          </label>
                          <input
                            type="radio"
                            checked={invoiceInternal === false}
                            onChange={() => setInvoiceInternal(false)}
                            name="costcenter"
                            id="external"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="">
                      {invoiceInternal ? (
                        <div>
                          <div className="inputSec-icon">
                            <Select
                              ref={costcenterRef}
                              openMenuOnFocus={true}
                              value={selectedDepartment > 0 && allDepartments?.length > 0 && [{ value: allDepartments?.find((d) => d?.Id === selectedDepartment)?.Id, label: allDepartments?.find((d) => d?.Id === selectedDepartment)?.Name }]}
                              onChange={(e) => setSelectedDepartment(Number(e.value))}
                              options={allDepartments?.length > 0
                                ? [{ value: 0, label: "Select Costcenter" },
                                ...allDepartments?.filter((d) => d?.Active)?.map((data) => ({ value: data?.Id, label: data?.Name }))
                                ]
                                : [{ value: 0, label: "Select Costcenter" }]
                              }
                              className="form-control p-0"
                              placeholder="Select Costcenter"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  border: "none",
                                  boxShadow: "none",
                                  padding: "8px 10px",
                                  borderRadius: 8,
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null
                              }}
                            />
                            <span onClick={() => costcenterRef.current?.focus()} className="custom-drop-down-span">
                              <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
                            </span>
                          </div>
                          <span className="txt-sm txt-gray p-1">
                            {allDepartments?.find((d) => d?.Id === selectedDepartment)?.ManagerName}
                          </span>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-6 col-md-6 col-xl-6 col-xl-6 mb-3">
                            <CreatableSelect
                              isClearable
                              className="CreatableSelect"
                              placeholder="Search Customer"
                              value={invoiceExternalDetails?.CompanyName && [{ value: invoiceExternalDetails?.CompanyName, label: invoiceExternalDetails?.CompanyName }]}
                              onChange={(e) => {
                                const companyName = e ? e.value : "";
                                const filteredCompany = allCompanyResponse.filter((d) => d?.CompanyName === companyName)[0]

                                const updatedAddress = filteredCompany ? filteredCompany?.Address : "";
                                const updatedZip = filteredCompany ? filteredCompany?.Zip : "";
                                const updatedCVR = filteredCompany ? filteredCompany?.CVR : "";
                                const updatedAtt = filteredCompany ? filteredCompany?.Att : "";
                                const updatedMail = filteredCompany ? filteredCompany?.Mail : "";

                                setInvoiceExternalDetails((prev) => ({
                                  ...prev,
                                  CompanyName: companyName,
                                  Address: updatedAddress,
                                  Zip: updatedZip,
                                  CVR: updatedCVR,
                                  Att: updatedAtt,
                                  Mail: updatedMail
                                }));
                              }}
                              onInputChange={(event) => setCompnayQueryString(event)}
                              options={allCompanyResponse?.map((d) => ({ value: d?.CompanyName, label: d?.CompanyName }))}
                              components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: () => null,
                                ClearIndicator: () => null,
                              }}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  boxShadow: "none",
                                  borderRadius: 8,
                                  padding: "8px 10px",
                                  fontSize: "18px",
                                  border: "1px solid #e8ecef",
                                  outline: "none"
                                }),
                              }}
                            />
                          </div>
                          <div className="col-6 col-md-6 col-xl-6 col-xl-6 mb-3">
                            <input
                              type="text"
                              value={invoiceExternalDetails?.Address}
                              onChange={(e) => setInvoiceExternalDetails((prev) => ({ ...prev, Address: e.target.value }))}
                              className="form-control"
                              placeholder="Address"
                            />
                          </div>

                          <div className="col-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                            <input
                              type="text"
                              value={invoiceExternalDetails?.Zip}
                              onChange={(e) => {
                                const value = e.target.value
                                if (value) {
                                  if (!isNaN(Number(value)) && Number(value) >= 0 && value?.length <= 8) {
                                    setInvoiceExternalDetails((prev) => ({ ...prev, Zip: value }))
                                  }
                                } else {
                                  setInvoiceExternalDetails((prev) => ({ ...prev, Zip: "" }))
                                }
                              }}
                              className="form-control"
                              placeholder="Zip"
                            />
                          </div>

                          <div className="col-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                            <input
                              type="text"
                              value={invoiceExternalDetails?.CVR ?? ""}
                              onChange={(e) => {
                                const value = e.target.value
                                if (value) {
                                  if (!isNaN(Number(value)) && Number(value) >= 0 && value?.length <= 13) {
                                    setInvoiceExternalDetails((prev) => ({ ...prev, CVR: value }))
                                  }
                                } else {
                                  setInvoiceExternalDetails((prev) => ({ ...prev, CVR: "" }))
                                }
                              }}
                              className={`form-control ${invoiceExternalDetails?.CVR && (invoiceExternalDetails?.CVR?.length !== 8 && invoiceExternalDetails?.CVR?.length !== 13) ? "border-danger" : ""}`}
                              placeholder="CVR/EAN-Number"
                            />
                            {invoiceExternalDetails?.CVR && (invoiceExternalDetails?.CVR?.length !== 8 && invoiceExternalDetails?.CVR?.length !== 13) && (
                              <span className="text-danger">CVR or EAN Number must be either 8 or 13 digits</span>
                            )}
                          </div>

                          <div className="col-4 col-md-2 col-lg-2 col-xl-2 mb-3">
                            <input
                              type="text"
                              value={invoiceExternalDetails?.Att}
                              onChange={(e) => setInvoiceExternalDetails((prev) => ({ ...prev, Att: e.target.value }))}
                              className="form-control"
                              placeholder="ATT"
                            />
                          </div>
                          <div className="col-12 col-md-6 col-xl-6 col-xl-6 mb-3">
                            <input
                              type="text"
                              value={invoiceExternalDetails?.Mail}
                              onChange={(e) => setInvoiceExternalDetails((prev) => ({ ...prev, Mail: e.target.value }))}
                              className="form-control"
                              placeholder="Mail"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* DeliveryMethod */}
                <div className="row list mt-3">
                  <div className="col-3 txt-md mb-2">
                    <span className="txt500">Delivery Method</span>
                  </div>
                  <div className="col-9 txt-md mb-2">
                    <div className="inputSec-icon">
                      <Select
                        ref={deliveryRef} openMenuOnFocus={true}
                        value={selectedDeliveryMethod && [{ value: selectedDeliveryMethod, label: selectedDeliveryMethod }]}
                        onChange={(e) => setSelectedDeliveryMethod(e.value)}
                        options={[{ value: "Delivery", label: "Delivery" }, { value: "Pickup", label: "Pickup" }]}
                        className="form-control p-0" placeholder="Select Delivery Method"
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border: "none",
                            boxShadow: "none",
                            padding: "8px 10px",
                            borderRadius: 8,
                          }),
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null
                        }}
                        isDisabled={orderDetails?.Meeting === null || !orderDetails?.Meeting}
                      />
                      <span onClick={() => deliveryRef.current.focus()} className="custom-drop-down-span">
                        <img className="custom-drop-down-image" alt="icon-user" src={iconArrow} />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row list mt-3 ">
                  {
                    orderDetails?.Meeting !== null && orderDetails?.Meeting?.Id &&
                    (<>
                      {/* Internal */}
                      <div className="col-3 txt-md mb-2 mt-2">
                        <span className="txt500">Internal</span>
                      </div>
                      <div className="col-3 txt-md mb-2">
                        <input
                          type="number"
                          value={updatedInternalAttendees}
                          onChange={(e) =>
                            setUpdatedInternalAttendees(
                              e.target.value <= 0
                                ? 0
                                : e.target.value >= 100
                                  ? 100
                                  : Number(e.target.value))
                          }
                          className="form-control form-control2"
                          placeholder="Item"
                        />
                      </div>

                      {/* External */}
                      <div className="col-3 txt-md mb-2 mt-2 text-center">
                        <span className="txt500">External</span>
                      </div>
                      <div className="col-3 txt-md mb-2">
                        <input
                          type="number"
                          value={updatedExternalAttendees}
                          onChange={(e) =>
                            setUpdatedExternalAttendees(
                              e.target.value <= 0
                                ? 0
                                : e.target.value >= 100
                                  ? 100
                                  : Number(e.target.value))
                          }
                          className="form-control form-control2"
                          placeholder="Item"
                        />
                      </div>
                    </>)

                  }

                  {/* Shared Comment */}
                  <div className="txt-md mb-2  mt-3">
                    <span className="txt500">Shared Comment</span>
                    <textarea
                      className="form-control mt-2"
                      rows={3}
                      value={updatedSharedComment}
                      onChange={(e) => setUpdatedSharedComment(e.target.value)}
                    />
                  </div>

                  {/* Internal Comment */}
                  <div className="txt-md mb-4  mt-3">
                    <span className="txt500">Internal Comment</span>
                    <textarea
                      className="form-control mt-2"
                      rows={3}
                      value={updatedInternalComment}
                      onChange={(e) => setUpdatedInternalComment(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </section>

          <div className="row">
            {editOrderProductStatus ? (
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-full btnOuterSave"
                  id="btnOuterSave"
                  onClick={() => SaveCanteenOrder()}
                >
                  Save
                </button>
              </div>
            ) : (
              <>
                {AllCateringOrderStatus && AllCateringOrderStatus.length > 0 &&
                  AllCateringOrderStatus.map((status, statusIndex) =>
                    <div className="col-3">
                      <button
                        onClick={() => UpdateCateringOrderStatus(status?.Id)}
                        disabled={orderDetails.OrderStatus?.Id === status?.Id}
                        className="btn btn-primary btn-lg btn-full"
                      >
                        {status?.Name}
                      </button>
                    </div>)}
                {/* <div className="col-4">
                  <button
                    onClick={() => UpdateCateringOrderStatus(2)}
                    disabled={orderDetails.OrderStatus?.Id === 2}
                    className="btn btn-primary btn-lg btn-full"
                  >
                    In Progress
                  </button>
                </div>
                <div className="col-4">
                  <button
                    onClick={() => UpdateCateringOrderStatus(3)}
                    disabled={orderDetails.OrderStatus?.Id === 3}
                    className="btn btn-primary btn-lg btn-full"
                  >
                    Completed
                  </button>
                </div> */}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}
